module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KCHQ33J6","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"icon":false,"className":"custom-class","maintainCase":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"ja"}],"fields":[{"name":"id","store":true},{"name":"title","store":true,"attributes":{"boost":20}},{"name":"lecturer","store":true,"attributes":{"boost":10}},{"name":"department","store":true,"attributes":{"boost":10}},{"name":"body","store":true},{"name":"term","store":true},{"name":"target","store":true},{"name":"classes","store":true},{"name":"credit","store":true},{"name":"html","store":true},{"name":"path","store":true},{"name":"tags","store":true,"attributes":{"boost":10}}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","crumbLabelUpdates":[{"pathname":"/about","crumbLabel":"名大の授業について"},{"pathname":"/apply","crumbLabel":"サポートスタッフ募集"},{"pathname":"/courses","crumbLabel":"授業一覧"},{"pathname":"/department","crumbLabel":"学部／研究科一覧"},{"pathname":"/faq","crumbLabel":"FAQ(よくある質問)"},{"pathname":"/form","crumbLabel":"お問い合わせ"},{"pathname":"/farewell","crumbLabel":"最終講義"},{"pathname":"/forteacher","crumbLabel":"教員の方へ"},{"pathname":"/links","crumbLabel":"リンク集"},{"pathname":"/news","crumbLabel":"お知らせ"},{"pathname":"/opencampus","crumbLabel":"オープンキャンパス"},{"pathname":"/relay","crumbLabel":"ラジオ公開講座"},{"pathname":"/research","crumbLabel":"名大の研究指導"},{"pathname":"/specials","crumbLabel":"過去の特集ページ"},{"pathname":"/tags","crumbLabel":"タグ一覧"},{"pathname":"/words","crumbLabel":"用語解説"},{"pathname":"/topics","crumbLabel":"TOPICS"},{"pathname":"/search","crumbLabel":"講義検索"},{"pathname":"/ranking","crumbLabel":"人気講義"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/nuocw-release/nuocw-release/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
